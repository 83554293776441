import "./style.scss";
import { useTranslation } from "react-i18next";
import unSubscribeImg from "@/assets/unsubscribe.png";

import Header from "./Header";
import { useMemo, useState, useEffect } from "react";


export default () => {
  const { t } = useTranslation();
  return (
    <div className="fill">
      <Header />
      <div className="unsubscribe-container">
        <img src={unSubscribeImg} width={120} alt="" />
        <div className="t">{t("subscribe.unSubTitle")}</div>
        <div className="st">{t("subscribe.unSubscribeTip")}</div>
      </div>
    </div>
  );
};
