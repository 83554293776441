import "./style.scss";
import { Input, Modal, Checkbox, Button, message } from "antd";
import { useTranslation } from "react-i18next";
import iconEmail from "@/assets/iconemail.png";

import subscribeImg from "@/assets/subscribe.png";
import { useMemo, useState, useEffect } from "react";

export default () => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);

  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [type, setType] = useState<any>([]);
  const [agree, setAgree] = useState<any>(false);
  const options = [
    { label: t("subscribe.role1"), value: "1" },
    { label: t("subscribe.role2"), value: "2" },
    { label: t("subscribe.role3"), value: "3" },
    { label: t("subscribe.role4"), value: "4" },
  ];

  const openModal = () => {
    setVisible(true);
    setEmail("");
    setType([]);
    setLoading(false);
  };

  const postSubscribe = async () => {
    if (!/^\w+(-+.\w+)*@\w+(-.\w+)*.\w+(-.\w+)*$/.test(email)) {
      message.open({
        type: "error",
        content: t("subscribe.emailError"),
      });
      return;
    }
    if (!agree) {
      message.open({
        type: "error",
        content: t("subscribe.agreeEmailError"),
      });
      return
    }
    if (type.length === 0) {
      message.open({
        type: "error",
        content: t("subscribe.roleError"),
      });
      return;
    }

    const API = process.env.REACT_APP_SUBSCRIBE_API_URL;

    const params = {
      email: email,
      role: type.join(","),
    };
    setLoading(true);
    return fetch(`${API}/api/defi/subscribe`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(params),
    })
      .then((response) => {
        if (!response.ok) {
          return response.text().then((text) => {
            throw new Error(text);
          });
        }
        return response.json();
      })
      .then((res) => {
        message.open({
          type: "success",
          content: t("subscribe.subscriptionSuccessful"),
        });
        setVisible(false);
      })
      .catch((err: any) => {
        try {
          message.open({
            type: "error",
            content: JSON.parse(err.message)["msg"],
          });
        } catch (error) {}
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <div className="subscribe" onClick={() => openModal()}>
        <img src={iconEmail} alt="" />
        {t("subscribe.subscribe")}
      </div>
      <Modal
        width={370}
        wrapClassName="subscribe-modal-wrap"
        className="subscribe-modal"
        title={""}
        open={visible}
        destroyOnClose
        maskClosable={false}
        footer={null}
        onCancel={() => setVisible(false)}
      >
        <div className="from-box">
          <div className="showimg">
            <img src={subscribeImg} alt="" />
          </div>
          <div className="t">{t("subscribe.subTitle")}</div>
          <div className="st">{t("subscribe.subDesc")}</div>
          <div>
            <Input
              className="email-input"
              placeholder={t("subscribe.emailInput")}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="checkbox-box">
            <div>{t("subscribe.me")}</div>
            <div>
              <Checkbox.Group
                options={options}
                value={type}
                onChange={(e) => setType(e)}
              />
            </div>
          </div>
          <div className="agree-box">
            <Checkbox value={agree} onChange={(v) => setAgree(v)}>
              {t("subscribe.agreeEmail")}
            </Checkbox>
          </div>
          <div>
            <Button
              type="primary"
              loading={loading}
              onClick={postSubscribe}
              className="subscribe-btn"
            >
              {t("subscribe.submit")}
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};
