/** @format */
import "../../home/Header/index.scss";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import LocaleSwitcherSelect from "@/components/LocaleSwitcher";

import logo_white from "@/assets/fill_logo_white.png";

export default () => {
  const [isScrolling, setIsScrolling] = useState(false);

  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (typeof window !== "undefined") {
      const listener = () => {
        setIsScrolling(window.scrollY > 150);
      };
      window.addEventListener("scroll", listener);
      return () => {
        window.removeEventListener("scroll", listener);
      };
    }
  }, []);

  return (
    <>
      <div className={`fill-header ${isScrolling ? "isScrolling" : ""}`}>
        <div className="fill-logo">
          <img src={logo_white} className="logo" alt="" />
        </div>
        <div className="router-container"></div>

        <div className="operate-container">
          <LocaleSwitcherSelect />
        </div>
      </div>
    </>
  );
};
